import request from '../util/request';

const apis = {
    assessList(params) {
        return request({
            url: "/V1/assessList",
            method: 'get',
            params
        })
    },
    addComment(data) {
        return request({
            url: "/V1/addComment",
            method: 'post',
            data
        })
    },
    addComplain(data) {
        return request({
            url: "/V1/addComplain",
            method: 'post',
            data
        })
    },
    getCommentInfo(params) {
        return request({
            url: "/V1/getCommentInfo",
            method: 'get',
            params
        })
    },
    getComplainInfo(params) {
        return request({
            url: "/V1/getComplainInfo",
            method: 'get',
            params
        })
    },
    assessCommentList(params) {
        return request({
            url: "/V1/assessCommentList",
            method: 'get',
            params
        })
    },
    assessInfo(params) {
        return request({
            url: "/V1/assessInfo",
            method: 'get',
            params
        })
    },
    getAssessDiscuss(params) {
        return request({
            url: "/V1/getAssessDiscuss",
            method: 'get',
            params
        })
    },
    assessCommentInfo(params) {
        return request({
            url: "/V1/assessCommentInfo",
            method: 'get',
            params
        })
    },
    assessCommentOpinionList(params) {
        return request({
            url: "/V1/assessCommentOpinionList",
            method: 'get',
            params
        })
    },
    assessApply(data) {
        return request({
            url: "/V1/assessApply",
            method: 'post',
            data: {
                plan: data.plan
            },
            params: {
                assessId: data.assessId
            }

        })
    },
    addAssessDiscuss(data) {
        return request({
            url: "/V1/addAssessDiscuss",
            method: 'post',
            data: {
                discuss: data.discuss
            },
            params: {
                assessId: data.assessId
            }

        })
    },
    addACommentOpinion(data) {
        return request({
            url: "/V1/addACommentOpinion",
            method: 'post',
            data: {
                opinion: data.opinion
            },
            params: {
                commentCode: data.commentCode
            }
        })
    },


}


export default apis