<template>
  <div class="info">
    <div class="info-content">
      <div class="info-content-l">
        <div class="info-content-l-header">
          <div class="msg">
            <div class="usericon">
              <el-avatar :size="53" :src="infoData.memberIcon"></el-avatar>
            </div>
            <div class="name">{{ infoData.memberName }}</div>
            <div class="tag">
              {{ infoData.apparatusType }}{{ infoData.apparatusName }}
            </div>
          </div>
          <div class="score">
            <div class="title">综合评分</div>

            <div class="stars" v-if="infoData.score">
              <el-rate
                @click.native.stop
                v-model="infoData.fakeScore"
                disabled-void-icon-class="iconfont icon-start"
                :icon-classes="[
                  'iconfont icon-start',
                  'iconfont icon-start',
                  'iconfont icon-start',
                ]"
                disabled
                :colors="['#FF3355', '#FF3355', '#FF3355']"
              >
              </el-rate>
            </div>
            <div class="starstext">{{ infoData.score }}分</div>
          </div>
        </div>
        <div class="info-content-l-table">
          <div class="table-contanier" v-if="infoData.common">
            <div v-for="(item, index) in infoData.common" :key="index">
             
              <div class="table-list" v-for="(citem,cindex) in item.children" :key="cindex">
                
                <div class="table-header">{{citem.paramKey}}</div>
                <div class="table-text">
                  <div class="stars">
                    <el-rate
                      disabled
                      v-model="citem.fakeScore"
                      @change="scoreChange(citem, citem.fakeScore)"
                      disabled-void-icon-class="iconfont icon-start"
                      :icon-classes="[
                        'iconfont icon-start',
                        'iconfont icon-start',
                        'iconfont icon-start',
                      ]"
                      :colors="['#FF3355', '#FF3355', '#FF3355']"
                    >
                    </el-rate>
                  </div>

                  <div class="text">您的评分<span class="red">{{citem.fakeScore}}分</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-content-l-text">
          <el-input
            type="textarea"
            disabled
            :rows="11"
            :placeholder="palceholderTxt"
            v-model="text "
          >
          </el-input>
        </div>
        <div class="info-content-l-uploadImg" v-if="titleType == 1">
          <div class="imgbox" v-for="(item,index) in infoData.file" :key="index">
            <img :src="item.filePath" alt=""  />
          </div>
          
          
        </div>
      </div>

      <div class="info-content-r">
        <div class="line"></div>
        <div class="topinfo__r_contanier">
          <div class="topinfo__r_header">
            <span> {{ titleType == 1 ? "点评榜TOP10" : "投诉榜TOP10" }}</span>
          </div>
          <div
            class="topinfo__r_content"
            v-for="(item, index) in topList"
            :key="index"
          >
            <div class="content-img top1" v-if="index == 0">
              <div
                class="box"
                @click="
                  tolibInfo(
                    '/instrumentlib/info',
                    item.apparatusId,
                    item.apparatusCatId
                  )
                "
              >
                <img
                  v-if="item.apparatusFileId"
                  :src="item.apparatusFileId"
                  alt=""
                />
                <img
                  v-if="!item.apparatusFileId"
                  src="../assets/test.png"
                  alt=""
                />
                <div class="content-title top1">{{ item.apparatusType }}</div>
                <span class="count"> {{ item.apparatusScore }}分 </span>
                <div class="topIcon">
                  <img v-if="index == 0" src="../assets/t1.png" alt="" />
                </div>
              </div>
            </div>
            <div class="content-img" v-if="index != 0">
              <div
                class="box2"
                @click="
                  tolibInfo(
                    '/instrumentlib/info',
                    item.apparatusId,
                    item.apparatusCatId
                  )
                "
              >
                <img
                  v-if="item.apparatusFileId"
                  :src="item.apparatusFileId"
                  alt=""
                />
                <img
                  v-if="!item.apparatusFileId"
                  src="../assets/test.png"
                  alt=""
                />
                <div class="content-title2">
                  {{ item.apparatusType }}{{ item.apparatusName }}
                </div>
                <div class="content-count">
                  <img src="../assets/commentScore.png" />
                  {{ item.apparatusScore }}分
                </div>
                <div class="topIcon">
                  <img v-if="index == 1" src="../assets/t2.png" alt="" />
                  <img v-if="index == 2" src="../assets/t3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import check from "../api/check";
import lib from "../api/lib";
import api from "../api/api";
export default {
  data() {
    return {
      topList: [],
      titleType: "",
      palceholderTxt: "",
      infoData: "",
      textarea: "",
    };
  },
  created() {
    this.top();
    this.titleChange();
    this.info();
  },
  components: {},
  computed: {
    text(){
      return this.titleType == 1 ? this.infoData.detail : this.infoData.description
    }
  },
  methods: {
    info() {
      if (this.titleType == 1) {
        this.getCommentInfo();
      } else {
        this.getComplainInfo();
      }
    },
    getCommentInfo() {
      let data = {
        commentCode: this.$route.params.code,
      };
      check.getCommentInfo(data).then((res) => {
        let data = res.data.data;
        

        if (!parseFloat(data.score)) {
          data.score = 0;
          data.fakeScore = 0;
        } else {
          data.score = parseFloat(data.score).toFixed(1);
          data.fakeScore= parseFloat(data.score).toFixed(1) / 2;
        }
        for(const i in data.common){
          let common = data.common[i];
          for(const j in common.children){
            let data = common.children[j];
            data.fakeScore = data.score / 2;
          }
        }
        this.infoData = res.data.data;
      });
    },
    getComplainInfo() {
      let data = {
        complainCode: this.$route.params.code,
      };
      check.getComplainInfo(data).then((res) => {
        let data = res.data.data;
        if (!parseFloat(data.score)) {
          data.score = 0;
          data.fakeScore = 0;
        } else {
          data.score = parseFloat(data.score).toFixed(1);
          data.fakeScore= parseFloat(data.score).toFixed(1) / 2;
        }
        for(const i in data.common){
          let common = data.common[i];
          for(const j in common.children){
            let data = common.children[j];
            data.fakeScore = data.score / 2;
          }
        }

        this.infoData = res.data.data;
      });
    },
    top() {
      let type = "";
      if (this.titleType == 1) {
        type = "comment";
      } else {
        type = "complain";
      }
      this.indexTopApparatus(type);
    },
    titleChange() {
      this.titleType = this.$route.params.type;
    },
    indexTopApparatus(type) {
      let data = {
        type: type,
      };
      api.indexTopApparatus(data).then((res) => {
        let list = res.data.data.data;
        list.forEach((item) => {
          if (!parseFloat(item.apparatusScore)) {
            item.apparatusScore = 0;
          } else {
            item.apparatusScore = parseFloat(item.apparatusScore).toFixed(1);
          }
        });
        this.topList = list;
      });
    },
  },
};
</script>
<style  lang="scss">
.info {
  .info-content {
    .info-content-l {
      .score,
      .table-text {
        .stars {
          .el-rate {
            height: auto;
            .el-rate__item {
              i {
                font-size: 27px;
              }
            }
          }
        }
      }
      .info-content-l-text {
        .el-textarea {
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          .el-textarea__inner {
            padding: 27px 19px;
            background: #fff;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.info {
  .info-content {
    display: flex;
    justify-content: space-between;
    .info-content-l {
      width: 860px;
      border-radius: 7px 7px 7px 7px;
      background: #fff;
      border: 1px solid #e9e9e9;
      .info-content-l-header {
        padding: 8px 27px 15px 27px;
        border-bottom: 1px solid #f0f0f0;
        .msg {
          display: flex;
          align-items: center;
          .name {
            margin-left: 13px;
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .tag {
            margin-left: 89px;
            font-size: 21px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
          }
        }
        .score {
          margin-top: 16px;
          display: flex;
          align-items: center;
          .title {
            font-size: 19px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-right: 11px;
          }
          .starstext {
            margin-left: 21px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff3355;
          }
        }
      }
      .info-content-l-table {
        padding: 27px 16px;
        min-height: 267px;
        .table-contanier {
          
          .table-list {
            display: flex;
            border: 1px solid #e9e9e9;
            background: #fbfbfb;
            align-items: center;
            margin-top: -1px;
            .table-header {
              padding: 0 19px;
              height: 53px;
              min-width: 151px;
              font-size: 19px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              display: flex;
              align-items: center;
              justify-content: center;
              border-right: 1px solid #e9e9e9;
            }
            .table-text {
              height: 53px;
              flex-grow: 1;
              display: flex;
              background: #fff;
              align-items: center;
              justify-content: center;
              .stars {
                margin-left: 31px;
              }

              .text {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-left: 35px;
                .red {
                  margin-left: 13px;
                  font-size: 16px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ff3355;
                }
              }
            }
          }
        }
      }
      .info-content-l-text {
        padding: 27px 16px;
      }
      .info-content-l-uploadImg {
        padding: 27px 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-top: 1px solid #e9e9e9;
        .imgbox {
          width: calc(25% - 15px);
          margin-top: 15px;
          margin-right: 15px;
          border-radius: 7px;
          font-size: 0;
          img{
            width: 100%;
          }
        }
      }
    }

    .info-content-r {
      width: 328px;
      border-radius: 7px;
      // border-top: 5px solid #0394FF;

      .topinfo__r_contanier {
        border: 1px solid #e9e9e9;
      }
      .line {
        height: 5px;
        background: #0394ff;
        border-radius: 7px 7px 0 0;
      }
      .topinfo__r_header {
        padding: 10px 19px;
        span {
          padding: 5px 0;
          border-bottom: 3px solid #0092ff;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #0394ff;
        }
      }
      .topinfo__r_content {
        .content-img {
          padding: 13px 19px;
          &.top1 {
            padding: 25px 19px;
            position: relative;
            .count {
              padding: 3px 6px;
              position: absolute;
              right: 13px;
              top: 13px;
              font-size: 15px;
              border-radius: 23px;
              border: 1px solid #ffffff;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .box {
            position: relative;
            img {
              width: 100%;
              border-radius: 7px 7px 0 0;
            }
            .topIcon {
              width: 53px;
              position: absolute;
              left: -8px;
              top: -16px;
            }
          }
          .box2 {
            min-height: 88px;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            grid-column-gap: 15px;
            grid-row-gap: 15px;
            position: relative;
            img {
              width: 100%;
              border-radius: 7px;
              grid-row-start: 1;
              grid-row-end: 3;
            }
            .topIcon {
              width: 40px;
              position: absolute;
              left: -6px;
              top: -12px;
            }
            .content-title2 {
              font-size: 19px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            .content-count {
              display: flex;
              align-items: flex-end;
              font-size: 16px;
              font-family: Helvetica-Regular, Helvetica;
              font-weight: 400;
              color: #ff5c5c;
              img {
                width: 35px;
                margin-right: 3px;
              }
            }
          }
        }

        .content-title {
          &.top1 {
            //   width: 217px;
            position: absolute;
            padding: 13px 21px;
            top: 50%;

            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #ffffff;
            font-size: 19px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            border-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)
              )
              1 1;
          }
        }
      }
    }
  }
}
</style>